import BTHttp from '@/core/common/request'

/**
 * 保单详情查询
 * @param orderNo
 */
export function policyDetail (orderNo) {
    return BTHttp({
        url: `/order/policy/detail/` + orderNo,
        method: 'get'
    })
}

/**
 * 批单详情查询
 * @param orderCorrectNo
 * @returns {*}
 */
export function correctDetail (orderCorrectNo) {
    return BTHttp({
        url: `/order/correct/detail/` + orderCorrectNo,
        method: 'get'
    })
}


export function selectPage (obj) {
    return BTHttp({
        url: `/order/page`,
        method: 'post',
        data: obj
    })
}

export function unpayPage (obj) {
    return BTHttp({
        url: `/order/unpayPage`,
        method: 'post',
        data: obj
    })
}

export function settlePage (obj) {
    return BTHttp({
        url: `/order/settlePage`,
        method: 'post',
        data: obj
    })
}

/**
 * 支付批次分页查询
 * @param obj
 * @returns {*}
 */
export function payBatchPage (obj) {
    return BTHttp({
        url: `/payBatch/page`,
        method: 'post',
        data: obj
    })
}

/**
 * 结算批次分页查询
 * @param obj
 * @returns {*}
 */
export function settleBatchPage (obj) {
    return BTHttp({
        url: `/settleBatch/page`,
        method: 'post',
        data: obj
    })
}

/**
 * 资金账户记录分页查询
 * @param obj
 * @returns {*}
 */
export function fundAccountMyBill (obj) {
    return BTHttp({
        url: `/fund/account_bill/myBill`,
        method: 'post',
        data: obj
    })
}


/***
 *  字典
 */
export function getDictData () {
    return BTHttp({
        url: `/fund/account_bill/dict`,
        method: 'get'
    })
}

