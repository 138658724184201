<template>
    <div class="card-container">
        <van-collapse v-model="activeNames" v-if="isCollapse">
            <van-collapse-item name="1" :title="title">
                <template slot="title">
                    <div class="card-label">
                        <span class="circle-badge" v-if="badge !== undefined && badge !== null">{{badge}}</span>
                        <span class="card-title">{{title}}</span>
                    </div>
                </template>
                <slot></slot>
            </van-collapse-item>
        </van-collapse>
        <div v-else-if="badge || title">
            <div class="card-header">
                <div class="card-label">
                    <span class="circle-badge" v-if="badge !== undefined && badge !== null">{{badge}}</span>
                    <span class="card-title">{{title}}</span>
                </div>
                <div class="card-link">
                    <slot name="header-right"></slot>
                </div>
            </div>
            <slot></slot>
        </div>
        <div v-else>
            <slot></slot>
        </div>

        <template v-if="isCollapse && activeNames.length == 0">
            <div>
                <slot name="collapsed"></slot>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "RoundCornerContainer",
        props: {
            isCollapse: {type: Boolean, default: false},
            title: {type: String, default: ''},
            badge: {type: Number}
        },
        data () {
            return {
                activeNames: ['1']
            }
        }
    }
</script>
<style lang="scss">
    .card-container {
        .van-collapse-item__content {
            padding: 0;
        }
        .van-collapse-item > .van-cell:not(:last-child)::after {
            border-bottom: 0;
        }
        .van-collapse-item__title {
            position: relative;
            padding: 10px 10px 0;
            display: flex;
            &:after {
                position: absolute;
                bottom: 0px;
                left: 0px;
                content: " ";
                height: 1Px;
                width: 100%;
                background-color: $line-color;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
                z-index: 3;
            }

            .card-title {
                &:after {
                    width: 100%;
                }
            }
        }
        .circle-badge {
            float: left;
            background: $badge-bg-color;
        }
    }
</style>
<style lang="scss" scoped>
    .card-container {
        .card-collapse-title {
            display: inline-block;
            line-height: 2px;
            border-bottom: 2px solid #1e8ffa;
            padding-bottom: 5px;
        }
        .card-title {
            text-align: left;
            margin: 0;
            font-size: 15px;
            padding: 5px 6px;
            font-weight: 600;
            color: $card-title-color;
            position: relative;
            vertical-align: bottom;
            display: inline-block;
            &:after {
                /*width: 2px;
                height: 18px;  left: 3px;
                top: 8px;*/
                height: 2px;
                left: 3px;
                bottom: 0px;
                background: $main-color;
                display: block;
                content: " ";
                position: absolute;
                border-radius: 5px;
                z-index: 5;
            }
            div {
                float: left;
                color: #333;
            }

        }
        .card-label {
            .circle-badge {
                margin-top: 8px;
            }
        }

    }
</style>
