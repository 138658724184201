<template>
    <div class="app-container order-detail">
        <RoundCornerContainer class="order-header">
            <van-row :span="24">
                <van-col :span="24" class="product-name">{{ data.productName }}</van-col>
                <van-col :span="20">
                    <div class="order-info-item">
                        <span class="label">订单号:</span>
                        <span class="value">{{ orderPolicyNo }}</span>
                    </div>
                    <div class="order-info-item" v-if="data.policyNo ">
                        <div class="label">保单号:</div>
                        <div class="value">{{ data.policyNo }}</div>
                    </div>
                    <div class="order-info-item" v-if="data.correctNo ">
                        <div class="label">批单号:</div>
                        <div class="value">{{ data.correctNo }}</div>
                    </div>

                    <div class="order-info-item" v-if="data.shippingInfo">
                        <div class="label">起运日期:</div>
                        <div class="value">{{ data.shippingInfo.startTime }}</div>
                    </div>
                    <div class="order-info-item">
                        <div class="label">签单日期:</div>
                        <div class="value">{{ data.signTime ? data.signTime : data.correctSignTime }}</div>
                    </div>
                </van-col>
            </van-row>
            <div :style="{ color: orderStatusFail?'#ed6a0c':'#07c160'}" @click="showOrderAction=true"
                 class="order-last-action" v-if="!!orderLastAction">
                {{orderLastAction}}
            </div>
            <div class="order-last-action">
                <!--<span style=" color: #07c160">{{data.statusGroupName}}</span>-->

                <span class="price-text">保费: <b
                    class="price-amount">¥{{ this.$util.formatMoneyNoZero(data.totalPremiumAmountYuan) }}</b>元</span>
            </div>
        </RoundCornerContainer>


        <RoundCornerContainer class="containter" title="投保人信息" v-if="data.applicantInfo">
            <van-cell>
                <div class="row" v-if="data.applicantInfo.name">
                    <div class="address_title">企业名称</div>
                    <div class="address_content">{{ data.applicantInfo.name }}</div>
                </div>
                <div class="row" v-if="data.applicantInfo.certificateType">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{ data.applicantInfo.certificateTypeDesc }}</div>
                </div>
                <div class="row" v-if="data.applicantInfo.certificateNo">
                    <div class="address_title">证件号码</div>
                    <div class="address_content">{{ data.applicantInfo.certificateNo }}</div>
                </div>
                <div class="row" v-if="data.applicantInfo.address">
                    <div class="address_title">企业详细地址</div>
                    <div class="address_content">{{ data.applicantInfo.address }}</div>
                </div>
                <van-divider/>

                <div class="row" v-if="data.applicantInfo.contact">
                    <div class="address_title">联系人姓名</div>
                    <div class="address_content">{{ data.applicantInfo.contact }}</div>
                </div>
                <div class="row" v-if="data.applicantInfo.phone">
                    <div class="address_title">联系人手机号</div>
                    <div class="address_content">{{ data.applicantInfo.phone }}</div>
                </div>
                <div class="row" v-if="data.applicantInfo.email">
                    <div class="address_title">联系人邮箱</div>
                    <div class="address_content">{{ data.applicantInfo.email }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="被保人信息" v-if="data.insuredInfo.personType===1">
            <van-cell>
                <div class="row" v-if="data.insuredInfo.name">
                    <div class="address_title">被保人</div>
                    <div class="address_content">{{ data.insuredInfo.name }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.identityTypeText">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{ data.insuredInfo.certificateTypeDesc }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.identityNo">
                    <div class="address_title">证件代码</div>
                    <div class="address_content">{{ data.insuredInfo.identityNo }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.birthday">
                    <div class="address_title">出生日期</div>
                    <div class="address_content">{{ data.insuredInfo.birthday }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.phone">
                    <div class="address_title">手机号码</div>
                    <div class="address_content">{{ data.insuredInfo.phone }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.email">
                    <div class="address_title">邮箱地址</div>
                    <div class="address_content">{{ data.insuredInfo.email }}</div>
                </div>
                <van-divider/>
                <div class="row" v-if="data.insuredInfo.contact">
                    <div class="address_title">联系人姓名</div>
                    <div class="address_content">{{ data.insuredInfo.contact }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.phone">
                    <div class="address_title">联系人手机号</div>
                    <div class="address_content">{{ data.insuredInfo.phone }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.email">
                    <div class="address_title">联系人邮箱</div>
                    <div class="address_content">{{ data.insuredInfo.email }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="被保人信息" v-if="data.insuredInfo.personType===2">
            <van-cell>
                <div class="row" v-if="data.insuredInfo.name">
                    <div class="address_title">企业名称</div>
                    <div class="address_content">{{ data.insuredInfo.name }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.certificateType">
                    <div class="address_title">证件类型</div>
                    <div class="address_content">{{ data.insuredInfo.certificateTypeDesc }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.certificateNo">
                    <div class="address_title">证件号码</div>
                    <div class="address_content">{{ data.insuredInfo.certificateNo }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.address">
                    <div class="address_title">企业详细地址</div>
                    <div class="address_content">{{ data.insuredInfo.address }}</div>
                </div>
                <van-divider/>
                <div class="row" v-if="data.insuredInfo.contact">
                    <div class="address_title">联系人姓名</div>
                    <div class="address_content">{{ data.insuredInfo.contact }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.phone">
                    <div class="address_title">联系人手机号</div>
                    <div class="address_content">{{ data.insuredInfo.phone }}</div>
                </div>
                <div class="row" v-if="data.insuredInfo.email">
                    <div class="address_title">联系人邮箱</div>
                    <div class="address_content">{{ data.insuredInfo.email }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <!--货物信息-->
        <RoundCornerContainer class="containter" title="货物信息" v-if="data.goodsInfo">
            <van-cell>
                <div class="row" v-if="data.goodsInfo.bigTypeName">
                    <div class="address_title">货物大类</div>
                    <div class="address_content">{{ data.goodsInfo.bigTypeName }}</div>
                </div>
                <div class="row" v-if="data.goodsInfo.detailTypeName">
                    <div class="address_title">货物细类</div>
                    <div class="address_content">{{ data.goodsInfo.detailTypeName }}</div>
                </div>
                <div class="row" v-if="data.goodsInfo.casingFashionName">
                    <div class="address_title">包装方式</div>
                    <div class="address_content">{{ data.goodsInfo.casingFashionName }}</div>
                </div>
                <div class="row" v-if="data.goodsInfo.riskDepict">
                    <div class="address_title">货物标的描述</div>
                    <div class="address_content">{{ data.goodsInfo.riskDepict }}</div>
                </div>
                <div class="row" v-if="data.goodsInfo.info">
                    <div class="address_title">唛头、货物数量、包装</div>
                    <div class="address_content">{{ data.goodsInfo.info }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <!-- 运输信息-->
        <RoundCornerContainer class="containter" title="运输信息" v-if="data.shippingInfo">
            <van-cell>
                <div class="row" v-if="data.shippingInfo.invoiceNo">
                    <div class="address_title">发票号码</div>
                    <div class="address_content">{{ data.shippingInfo.invoiceNo }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.waybillNo">
                    <div class="address_title">提/运单号</div>
                    <div class="address_content">{{ data.shippingInfo.waybillNo }}</div>
                </div>

                <div class="row" v-if="data.shippingInfo.licensePlateNo">
                    <div class="address_title">车牌号</div>
                    <div class="address_content">{{ data.shippingInfo.licensePlateNo }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.shipName">
                    <div class="address_title">船名</div>
                    <div class="address_content">{{ data.shippingInfo.shipName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.shipAge">
                    <div class="address_title">船龄</div>
                    <div class="address_content">{{ data.shippingInfo.shipAge }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.flightNo">
                    <div class="address_title">航班号</div>
                    <div class="address_content">{{ data.shippingInfo.flightNo }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.trainsNo">
                    <div class="address_title">火车列次号</div>
                    <div class="address_content">{{ data.shippingInfo.trainsNo }}</div>
                </div>

                <div class="row" v-if="data.shippingInfo.approachPortName">
                    <div class="address_title">途径港名称</div>
                    <div class="address_content">{{ data.shippingInfo.approachPortName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.startTime">
                    <div class="address_title">起运日期</div>
                    <div class="address_content">{{ data.shippingInfo.startTime }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>


        <!-- 运输信息-->
        <!--transportType 1、国内运输；2、出口运输、3、进口运输-->
        <!-- 国内起运地-->
        <RoundCornerContainer class="containter" title="起运地"
                              v-if="data.shippingInfo.transportType === 1 || data.shippingInfo.transportType === 2">
            <van-cell>
                <div class="row" v-if="data.shippingInfo.startProvinceName">
                    <div class="address_title">起运地</div>
                    <div class="address_content">{{ data.shippingInfo.startProvinceName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.startCityName">
                    <div class="address_title">起运港名称</div>
                    <div class="address_content">{{ data.shippingInfo.startCityName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.startString">
                    <div class="address_title">起运地描述</div>
                    <div class="address_content">{{ data.shippingInfo.startString }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="起运港国家/地区" v-if="data.shippingInfo.transportType === 3">
            <van-cell>
                <div class="row" v-if="data.shippingInfo.startCountryName">
                    <div class="address_title">起运港国家/地区</div>
                    <div class="address_content">{{ data.shippingInfo.startCountryName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.startCountryPortName">
                    <div class="address_title">起运港名称</div>
                    <div class="address_content">{{ data.shippingInfo.startCountryPortName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.startCountryString">
                    <div class="address_title">起运地描述</div>
                    <div class="address_content">{{ data.shippingInfo.startCountryString }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <!-- 国内目的地-->
        <RoundCornerContainer class="containter" title="目的地"
                              v-if="data.shippingInfo.transportType === 1 || data.shippingInfo.transportType === 3">
            <van-cell>
                <div class="row" v-if="data.shippingInfo.endProvinceName">
                    <div class="address_title">目的地</div>
                    <div class="address_content">{{ data.shippingInfo.endProvinceName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.endAreaName">
                    <div class="address_title">目的港名称</div>
                    <div class="address_content">{{ data.shippingInfo.endAreaName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.endString">
                    <div class="address_title">目的地描述</div>
                    <div class="address_content">{{ data.shippingInfo.endString }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="目的港国家/地区" v-if="data.shippingInfo.transportType === 2">
            <van-cell>
                <div class="row" v-if="data.shippingInfo.endCountryName">
                    <div class="address_title">目的港国家/地区</div>
                    <div class="address_content">{{ data.shippingInfo.endCountryName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.endCountryPortName">
                    <div class="address_title">目的港名称</div>
                    <div class="address_content">{{ data.shippingInfo.endCountryPortName }}</div>
                </div>
                <div class="row" v-if="data.shippingInfo.endCountryString">
                    <div class="address_title">目的地描述</div>
                    <div class="address_content">{{ data.shippingInfo.endCountryString }}</div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer class="containter" title="保险信息" v-if="data.insuranceInfo">
            <van-cell>
                <div class="row" v-if="data.insuranceInfo.mainInsuranceName">
                    <div class="address_title">主险</div>
                    <div class="address_content">
                        <template v-if="!!data.insuranceInfo.mainInsuranceUrl">
                            <a :href="data.insuranceInfo.mainInsuranceUrl" target="_blank">{{ data.insuranceInfo.mainInsuranceName }}</a>
                        </template>
                        <template v-else>
                            {{ data.insuranceInfo.mainInsuranceName }}
                        </template>
                    </div>
                </div>
                <div class="row" v-if="data.insuranceInfo.appendedInsuranceNameList">
                    <div class="address_title">附加险</div>
                    <div class="address_content">
                        <span v-for="item in data.insuranceInfo.appendedInsuranceNameList">
                                   {{ item }}、</br>
                        </span>
                    </div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer title="受益人">
            <!--<div class="no-content">法定</div>-->
            <van-cell style="padding:5px 15px ;">
                <div class="row">
                    <div class="address_title">法定</div>
                    <div class="address_content"></div>
                </div>
            </van-cell>
        </RoundCornerContainer>

        <RoundCornerContainer title="附件信息">
            <div class="epolicy">
                <van-cell :title="item.fileName" @click="previewDocUrl(item.fileName,item.fileUrl)"
                          v-for="(item,index) in data.orderFiles" :key="index" is-link/>
            </div>
        </RoundCornerContainer>

        <!-- 图标位置 -->
        <van-popup
            v-model="showplan"
            close-icon-position="top-left"
            position="bottom"
            :style="{ height: '30%' }"
        >
            <div class="plandetailbox">
                <van-row>
                    <van-col :span="8">险种名称</van-col>
                    <van-col :span="14">保障内容</van-col>
                </van-row>
                <van-row v-for="li in plandetail">
                    <van-col :span="8">{{ li.title }}</van-col>
                    <van-col :span="14">{{ li.value }}</van-col>
                </van-row>
            </div>

        </van-popup>

        <van-row class="product-bottom-bar" v-if="data.statusGroup == 3">
            <van-col offset="1" span="17">
                <div class="pay-amount-block">
                    <span class="pay-amount">支付金额: ¥{{ data.totalPremiumAmountYuan }}</span>
                </div>
            </van-col>
            <van-col span="6">
                <van-button type="danger" class="btn-pay" @click="handlePay">支付</van-button>
            </van-col>
            <!--              <van-col span="6">-->
            <!--                <van-button type="danger" @click="testChudan">出单</van-button>-->
            <!--              </van-col>-->
        </van-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import RoundCornerContainer from "@/core/component/product/RoundCornerContainer";
    import { correctDetail, policyDetail } from "@/modules/order/core/api";

    export default {
        components: {
            RoundCornerContainer,
        },
        name: "order_detail",
        data () {
            return {
                // 保障计划
                showplan: false,
                plandetail: null,
                // 展示操作记录
                showOrderAction: false,

                personList: [],//人员清单
                data: {
                    applicantInfo: {},
                    insuredInfo: {
                        personType: null
                    },
                    shippingInfo: {
                        transportType: null
                    }

                },
                orderStatusFail: true,
                orderLastAction: '',
                orderPolicyNo: null,
                orderType: '',
                loading: false,
            }
        },
        computed: {
            ...mapGetters({}),
        },
        activated () {
            this.orderPolicyNo = this.$route.query.orderPolicyNo;
            this.orderType = parseInt(this.$route.query.orderType);

            if (this.$util.isEmpty(this.orderPolicyNo)) {
                this.$tips.toast("缺少订单编号");
                this.$router.back(-1);
            }
            if (this.orderType === 1) {
                this.getOrderInfo()
            } else {
                this.getCorrectInfo();
            }
        },
        beforeDestroy () {
            this.$tips.hideLoading();
        },
        destroyed () {
        },
        methods: {

            //pdf预览
            previewDocUrl (title, link) {
                window.location.href = link;
            },

            goUrl (link) {
                this.$util.jumpLink(link);
            },

            // 获取订单详情
            getOrderInfo () {
                this.loading = true;
                this.$tips.showLoading();

                policyDetail(this.orderPolicyNo)
                    .then((response) => {
                        this.$tips.hideLoading();
                        this.loading = false;
                        if (response.code == 0) {
                            this.data = response.data;

                            // 失败的状态
                            const failOrderStatus = [3, 13, 16]
                            if (failOrderStatus.includes(this.data.orderStatus)) {
                                this.orderStatusFail = true
                            } else {
                                this.orderStatusFail = false
                            }
                            if (this.data.orderActions.length >= 1) {
                                this.orderLastAction = this.data.orderActions[0].actionText
                            }
                        } else {
                            this.$tips.toast(response.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$tips.hideLoading();
                        this.loading = false;
                        this.$tips.toast('系统正忙，请稍后再试');
                    });
            },

            // 获取订单详情
            getCorrectInfo () {
                this.loading = true;
                this.$tips.showLoading();

                correctDetail(this.orderPolicyNo)
                    .then((response) => {
                        this.$tips.hideLoading();
                        this.loading = false;
                        if (response.code == 0) {
                            this.data = response.data;
                        } else {
                            this.$tips.toast(response.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$tips.hideLoading();
                        this.loading = false;
                        this.$tips.toast('系统正忙，请稍后再试');
                    });
            }
        }
    }
</script>
<style lang="scss">
    .van-step--vertical .van-step__circle-container {
        left: -15px;
        font-size: 16px;
        -webkit-transform: translate(-46%, -50%);
        transform: translate(-46%, -50%);
    }

    .price-text {
        padding: 0 !important;
        line-height: 28px !important;
    }

    .price-text .price-amount {
        color: #ff5e69;
        font-size: 0.45333rem;
        font-weight: 500;
        margin-right: 0.10667rem;
    }
</style>
<style lang="scss" scoped>
    .plandetailbox {
        width: 100%;
        padding: 15px;

        .van-row {
            width: 100%;
            line-height: 26px;
            font-size: 14px;
            text-align: center;
            margin-bottom: 10px;

            &:first-child {
                background: #f1f1f1;
            }
        }
    }

    .order-detail {
        padding-top: 10px;

        .planbox {
            background: #f5f9fe;
            padding-left: 10px;
            border-top: 1px dashed #ddd;
            border-bottom: 1px dashed #ddd;

            .van-cell {
                background: #f5f9fe;

                &:after {
                    border-bottom: 0 !important;
                }
            }
        }

        .teamLink {
            .van-cell {
                color: #666;
                font-size: 13px;
                line-height: 32px;

                &:after {
                    border-bottom: 1px dashed #ddd;
                }

                .van-cell__right-icon {
                    height: 32px;
                    font-size: 0.42667rem;
                    line-height: 32px;
                }

                .van-cell__title {
                    flex: 3;
                }
            }

            .other {
                background: #f5f9fe;
            }

            .detail {
                background: #fff;

                .van-cell__value, .van-cell__right-icon {
                    color: #1e8ffa;
                }
            }

            .price {
                background: #fafafa;
                text-align: right;
                padding: 10px 15px;
                margin: 0;
                font-size: 12px;
                color: #444;

                span {
                    font-size: 17px;
                    color: #ff6666;
                }
            }
        }

        .order-header {
            margin-top: 10px;
            background: #fff;
            padding: 10px 10px 5px;
            line-height: 20px;
            position: relative;

            .product-name {
                width: 70%;
                margin: 0 0 8px;
                font-size: 18px;
                font-weight: 500;
            }

            .order-info-item {
                padding: 0 !important;
                font-size: 12px;
                line-height: 25px;
                display: flex;

                .label {
                    /*width: 60px;*/
                    padding: 0 5px 0 0;
                    color: #666;
                    font-size: 13px;
                }

                .value {
                    text-align: left;
                }
            }

            .order-status {
                position: absolute;
                right: 10px;
                top: 15px;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: url("~@/assets/images/img/bg_mark.png") no-repeat center center;
                background-size: cover;

                .circle {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                    &.circle1 {
                        width: 65px;
                        height: 65px;
                        left: 2px;
                        top: 2px;
                    }

                    &.circle2 {
                        width: 60px;
                        height: 60px;
                        left: 4px;
                        top: 4px;
                    }
                }

                .order-status-text {
                    position: absolute;
                    top: 25px;
                    left: -7px;
                    display: inline-block;
                    color: #999;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 30px;
                    width: 92px;
                    background: transparent;
                    text-align: center;
                    transform: rotate(30deg);
                }

                .van-icon {
                    position: absolute;
                    right: 0px;
                    top: 2px;
                    color: #999;
                }
            }

            .order-last-action {
                width: 70%;
                padding: 6px;
                position: relative;
                border-top: dotted 1px #eee;
                margin-top: 10px;
            }

            ::v-deep .van-steps__items {
                margin-bottom: 0px;
            }
        }

        ::v-deep .van-step--finish .van-step__circle {
            background-color: #2991fa;
        }

        ::v-deep .van-step--finish .van-step__line {
            background-color: #2991fa;
        }
    }

    .card-container {
        .van-cell {
            padding: 0 15px;
        }

        .address_title {
            /*width: 130px;*/
            padding: 0;
            color: #666;
            font-size: 13px;
        }

        .address_content {
            flex: 1;
            word-break: break-all;
            text-align: right;
            padding-left: 15px;
        }

        .row {
            width: 100%;
            display: flex;
            padding: 3px 0;
            font-size: 13px;
        }

        .priceAll {
            text-align: right;
            font-size: 13px;
            color: #454545;
            line-height: 40px;
            padding: 0 15px 0px;
            margin: 0;

            span {
                font-size: 14px;
                color: #ff6666;
            }

            background-color: white;
        }

        &.attachment {
            .van-cell {
                padding: 10px 10px;
                font-size: 13px;
                color: #454545;
            }
        }

        .no-content {
            line-height: 40px;
            text-align: left;
            padding-left: 20px;
        }
    }

    .btn-blue {
        background: #1e8ffa;
        color: #fff;
        border-color: #1e8ffa;
    }

    .product-bottom-bar {
        box-shadow: 0 -4px 10px hsla(0, 0%, 60%, .1);
        position: fixed;
        bottom: 0px;
        left: auto;
        width: 100%;
        background-color: white;
        z-index: 100;

        .price {
            font-size: 18px;
            color: #ff771c;
            font-weight: 500;
        }

        .van-button {
            border-radius: 0px;
            width: 100%;
            /*background: #fff;*/
            /*border: 1px #fff solid*/
        }

        .btn-service {
            border-right: solid 1px #eee;
            font-size: 18px;
        }

        .bottom-amount {
            width: 100%;
            height: 100%;
            text-align: center;
            padding-left: 10px
        }

        .btn-back {
            border-right: 1px #eee solid;

            .van-icon {
                font-size: 14px
            }
        }

        .btn-pay {
            background: #1e8ffa;
            border: 1px #1e8ffa solid
        }
    }

    .benefit-title {
        font-size: 14px;
        color: #969696;
        padding-right: 15px;
    }

    .address_content {
        flex: 1;
        word-break: break-all;
        text-align: right;
    }

    .row {
        width: 100%;
        display: flex;
        padding-top: 8px;
    }

    .group_title {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
    }

    .step_item {
        h3 {
            font-size: 14px;
            font-weight: normal;
            padding: 5px;
            margin: 0px
        }

        p {
            font-size: 14px;
            color: #666;
            margin: 5px
        }
    }

    .bottom-insure-again {
        text-align: center;
        color: #999;

        .btn-link {
            text-align: center;
            border: none;
            background: transparent;
            display: inline-block;
            margin: 5px auto;
            color: $blue;
        }
    }

    .buttons {
        .btn-orange {
            background-color: #4b0;
            color: #fff;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
            padding: 5px 10px;
        }

        .btn-red {
            color: #fff;
            background-color: #e64340;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
            padding: 5px 10px;
        }

        .btn-green {
            color: #fff;
            background-color: #4b0;
            border-radius: 3px;
            border: 0;
            padding: 5px 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .btn-blue {
            float: right;
            padding: 5px 10px;
            background-color: #1a92ee;
            color: #fff;
            border-radius: 3px;
            border: 0;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-fade-leave-active {
        transition: all .5s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */
    {
        transform: translateY(-10px);
        opacity: 0;
    }

    .load-more {
        padding: 4px 0;
        text-align: center;
    }

    .sign-image {
        width: 100%;
    }

    .insurer-name {
        color: #e4af63;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 500;
    }

    .confirm-title {
        font-size: 16px;
    }

    .preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 40px);
        padding: 0px;
        background: #f1f1f1;

        .frame-preview {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            border: none;
            background-color: transparent;
            /*position: absolute;*/
            /*top: 0;*/
            /*left: 0;*/
            /*z-index: 100;*/
        }

        .bottom-btn {
            height: 40px;
            position: absolute;
            bottom: 0px;
            display: flex;
            width: 100%;
            z-index: 100;

            .btn-read {
                background-color: $btn-main;
                color: #fff;
                border-radius: 0px;
            }
        }

    }

    .confirm-title {
        font-size: 16px;
    }

    .insurer-name {
        color: #e4af63;
        font-weight: 500;
        padding: 0px;
    }

    .confirm-product-name {
        color: $blue;
        font-weight: 500;
        padding: 0px;
    }

    .confirmed-tips {
        color: $blue;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
    }

    .confirm-tips-warning {
        background-color: #fffae7;
        border-color: #fffae7;
        color: rgba(0, 0, 0, 1);
        padding: 5px;
        text-align: justify;
        text-justify: inter-ideograph;
    }

    .tips-p {
        padding-left: 5px;
        margin: 0px;
    }

    .btn-single {
        line-height: 250%;
        font-size: 15px;
        height: 100%;
        width: 100%;
        border: none;
        background: #2991fa;
        color: #FFFFFF;
        font-weight: 400;
        border-radius: 60px 60px 60px 60px;
    }

    .pay-amount {
        color: $orange;
    }

    .money {
        font-size: 18px;
    }

    .containter {
        padding-bottom: 10px;
        background-color: white;
    }

    .button-container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .popup-order-action {
        max-height: calc(100% - 150px);
    }

    .epolicy {
        width: 100%;
        padding: 10px 0;

        span {
            color: #648df6;
            display: inline-block;
            width: 100%;
        }
    }

    .urlbox {
        width: 80%;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;

        img {
            width: 80px;
            margin: 0 auto;
            display: block;
        }

        p {
            color: #333;
            margin: 20px 0 20px 0;
        }

        div {
            color: #666;
        }

        .van-button {
            width: 100%;
            height: 40px;
            line-height: 40px;
            margin-top: 15px;
            font-size: 14px;
            background: $whiteBlue;
            border-color: $whiteBlue;
        }
    }

    .product-bottom-bar {
        box-shadow: 0 -4px 10px hsla(0, 0%, 60%, .1);
        position: fixed;
        bottom: 0px;
        width: 100%;
        background-color: white;
        z-index: 100;
    }

    .pay-amount-block .pay-amount {
        font-size: 15px;
        margin-top: 10px;
        font-weight: 500;
        display: block;
        color: #333;
        font-family: sans-serif;
    }
</style>
